import React from "react"
import { Helmet } from "react-helmet"
import { trackCustomEvent } from "gatsby-plugin-google-analytics"
import { withStyles } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"
import MainImage from "../images/marketing/Salon-Business-Plan-Main-Image-LaunchPlan-Software.jpg"
import DescriptionImage from "../images/marketing/Yoga-Studio-Business-Plan-Company-Description-LaunchPlan-Software.jpg"
import ExecutiveSummaryImage from "../images/marketing/Spa-Massage-Therapy-Business-Plan-Executive-Summary-LaunchPlan-Software.jpg"
import FinanacialPlanImage from "../images/marketing/Barber-Shop-Financial-Plan-LaunchPlan-Software.jpg"
import OperationsImage from "../images/marketing/Nail-Salon-Operations-Plan-LaunchPlan-Software.jpg"
import MarketingImage from "../images/marketing/Gym-Marketing-Sales-Plan-LaunchPlan-Software.jpg"
import PublicLayout from "../components/layouts/PublicLayout"
import Button from "../components/button"

const styles = theme => ({
  root: {
    backgroundColor: "white",

    '& img': {
      marginBottom: '40px',
      maxWidth: '100%'
    }
  },
  borderBottom: {
    borderBottom: `1px solid ${theme.palette.primary.borderColor}`,
  },
  text: {
    margin: '0 auto',
    maxWidth: 600,

    '& p': {
      marginBottom: '20px'
    },

    '& h2, h3': {
      margin: '100px 0 30px'
    }
  },
  title: {
    color: theme.palette.primary.main,
    textTransform: "uppercase",
  },
  headline: {
    margin: "1rem 0",
  },
  separator: {
    width: 60,
    height: 4,
    backgroundColor: theme.palette.primary.main,
    borderRadius: 4,
    margin: "1.5rem 0",
  },
  footer: {
    marginTop: '120px'
  }
})

const SpaPage = props => {
  const { classes } = props

  return (
    <PublicLayout
      headline={"Write Business Plans for Salons, Spas and Studios with LaunchPlan"}
      canonicalUrl="https://launchplan.com/salon-spa-studio-business-plan-software"
    >
      <Helmet>
        <title>Salon, Spa & Studio Business Plan Software | LaunchPlan</title>
        <meta
          name="description"
          content="Write business plans for hair and nail salons, barber shops, spas, yoga studios, gyms, and other health and beauty businesses with LaunchPlan software."
        />
        <meta
          property="og:title"
          content="Salon, Spa & Studio Business Plan Software | LaunchPlan"
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://launchplan.com/salon-spa-studio-business-plan-software" />
        <meta
          property="og:image"
          content="https://launchplan.com/launchplan-logo-vertical.png"
        />

        {/* <meta property="og:image:type" content="image/png" />
          <meta property="og:image:width" content="206" />
          <meta property="og:image:height" content="112" /> */}
        <meta
          property="og:image:alt"
          content="The LaunchPlan logo, a rocket launching"
        />
      </Helmet>
      <section className={classes.root}>
        <img src={MainImage} alt='' />
        <div className={classes.text}>
          <Typography>
            The success of a salon, spa, or other health and beauty business ultimately begins and ends with outstanding service and customer loyalty. Is your customer leaving your business feeling good enough about their looks, physical health or state of mind that they will return regularly?
          </Typography>
          <Typography>
            But beyond these crucial elements, you need a plan to attract the right customers to your location and formulate the right business model to turn a profit. That’s where creating a business plan for your salon, spa or wellness studio becomes essential.
          </Typography>
          <Typography>
            Writing a business plan — whether it’s for a hair or nail salon, a day spa or massage therapy practice, or a pilates or yoga studio — requires you to think through these factors to chart a course to profitability. That’s what potential investors want to see.
          </Typography>
          <Typography>
            Even if you don’t need a business plan for funding, simply going through the process of creating one increases your chance of success. A recent Harvard Business Review study found that entrepreneurs who write business plans are <a target="_blank" href="https://hbr.org/2017/07/research-writing-a-business-plan-makes-your-startup-more-likely-to-succeed">16% more likely to achieve viability</a> than those who do not.
          </Typography>
          <Typography>
            The outline of a business plan features five main areas, including an executive summary, a company description, an operations plan, marketing & sales plans, and a financial plan.
          </Typography>
          <Typography>
            Read on for an overview, or let our <a target="_blank" href="https://launchplan.com/">business plan software</a> for salons, spas and studios walk you through each step and automate your financial forecasts with a 60 day risk-free guarantee. You'll get a 100% refund if you're not 100% satisfied..
          </Typography>
          <Button
            className={classes.freeTrailButton}
            href={`${process.env.GATSBY_APP_HOST}/signup?selection=FREE_ACCOUNT`}
            onClick={() =>
              trackCustomEvent({
                category: "Spa Plan Help",
                action: "Click",
                label: "Free Trial In Overview",
              })
            }
          >
            {"Create Your Plan with LaunchPlan"}
          </Button>
          <Typography component="h2" variant="h4">
            Executive Summary
          </Typography>
          <div className={classes.separator}></div>
          <img src={ExecutiveSummaryImage} alt='' />
          <Typography>
            The executive summary addresses the key parts of your plan from a high level and is an expanded elevator pitch to potential investors and banks. It should answer common questions about your health or beauty business, like its fee structure (ex. per appointment or through monthly subscriptions), physical layout, target customers, neighborhood and surrounding businesses.
          </Typography>
          <Typography>
            The executive summary should be written as if it’s the only thing an investor will read — because they may not have time for anything else.
          </Typography>
          <Typography>
            As a salon, spa or wellness studio, some tangible details to feature in the executive summary include:
          </Typography>
          <ul>
            <li>
              <Typography>
                A few illuminating statistics about the health or beauty market you’re entering

                (Ex. “While there are three high-end hair salons in Jackson, MI, each was established at least 15 years ago, and they have not cornered the 18- to 34-year-old market seeking modern hairstyles. Our new salon instends to target this group.”)
              </Typography>
            </li>
            <li>
              <Typography>
                Four to six SMART (specific, measurable, assignable, realistic and time-based) goals for the first year of operations

                (Ex. “Average eight massages per day in our spa’s first six months of operation,” or “Sign up and maintain at least 75 subscribers to weekly pilates classes in the first year of operation.”)
              </Typography>
            </li>
            <li>
              <Typography>
                A high-level overview of upfront expenses, the amount of funding needed and revenue projections over the first three years of the business
              </Typography>
            </li>
          </ul>
          <Typography component="h2" variant="h4">
            Company Description
          </Typography>
          <div className={classes.separator}></div>
          <img src={DescriptionImage} alt='' />
          <Typography>
            The company description goes into more detail about your business’ background and vision, including its history and team, as well as its industry and competitive landscape. It addresses the company’s long-term goals and how it plans to achieve them.
          </Typography>
          <Typography>
            This section also highlights how your company fits in with your market’s trends, and how it can stand apart from or coexist with competitors. Does your barber shop differentiate itself by serving beverages to clients, or by providing a free haircut to young children with an adult haircut?
          </Typography>
          <Typography>
            The anchors of the company description are the mission and vision statements. While the mission statement articulates the day-to-day benefits your company will provide to its customers, employees (including yourself) and shareholders; the vision statement takes a broader view of how you will move the market forward and benefit the community at large.
          </Typography>
          <Typography>
            For example, a vision statement for a new yoga studio might be to “promote a healthier, more mindful community in Athens, GA.”
          </Typography>
          <Typography>
            Consider exploring the following aspects of your health or beauty market and how your company can thrive within it:
          </Typography>
          <ul>
            <li>
              <Typography>
                What is the overall health picture of your market? Is it trending younger and more fit, or older and more sedentary? How is your business uniquely positioned to serve this market?
              </Typography>
            </li>
            <li>
              <Typography>
                Given your location and the demographics of your city or town, does it make sense to offer at-home haircuts, massages or other services at a premium price?
              </Typography>
            </li>
            <li>
              <Typography>
                From an employment perspective, does your area have an adequate pool of talented practitioners for your salon, spa or wellness studio?
              </Typography>
            </li>
          </ul>
          <Typography component="h2" variant="h4">
            Operations Plan
          </Typography>
          <div className={classes.separator}></div>
          <img src={OperationsImage} alt='' />
          <Typography>
            If an investor makes it to your operations plan, then they’re likely intrigued and want the nitty-gritty on how you’ll turn your ideas into action. The operations plan details the physical assets, personnel and technology you will need to become a viable salon, spa or studio.
          </Typography>
          <Typography>
            This section of a business plan details capital and expense requirements like the physical location of your health or beauty business, its styling or fitness equipment, any products to be sold and the point-of-sale system, training costs, website, digital advertising, and signage.
          </Typography>
          <Typography>
            The operations plan should also explain how your business will function on a day-to-day basis, such as how appointments will be scheduled and confirmed, the standard process for each appointment, and how clients will be followed up with afterward.
          </Typography>
          <Typography>
            If you have a partner or other lead practitioners already defined, their roles in day-to-day operations should also be clearly described here, in addition to your own role. Detail the planned number of practitioners you will employ or pay as independent contractors (ex. massage therapists, hair stylists, yoga instructors, etc.), and how this will change over time.
          </Typography>
          <Typography component="h2" variant="h4">
            Marketing & Sales Plans
          </Typography>
          <div className={classes.separator}></div>
          <img src={MarketingImage} alt='' />
          <Typography>
            The marketing & sales plans outline who your target customers will be, how you will reach them, and how you will convert them into regularly paying customers.
          </Typography>
          <Typography>
            For example, if you plan to target millennials, you might invest in garnering positive reviews and running ads on Yelp, or promoting sign-up offers or appointment discounts on Instagram.
          </Typography>
          <Typography>
            If you’re instead targeting affluent baby boomers (just <a target="_blank" href="https://www.pewresearch.org/internet/fact-sheet/social-media/">8% of Americans ages 65+ say they use Instagram</a>), you might focus on PR campaigns to be featured in local newspapers, magazines and online news sites.
          </Typography>
          <Typography>
            Once you’ve defined your niche, you can perform market research and develop a plan to reach that audience. For digital marketing, SEO tools like <a target="_blank" href="https://www.semrush.com/">SEMrush</a> and <a target="_blank" href="https://moz.com/">Moz</a> can reveal the volume of different health and beauty Google searches (ex. “wedding hair salon in Charleston SC”) so that you can gauge market demand. You then can check out the websites and social media presence for competitors who are appearing for these searches to get ideas or determine what you could do differently.
          </Typography>
          <Typography>
            Regardless of your target audience, word-of-mouth referrals can be very powerful for service-based businesses. There may be partnership opportunities with complementary businesses that will give your clients a positive experience beyond your doors. For example, your hair salon could offer reciprocal discounts for mutual clients of a local nail salon.
          </Typography>
          <Typography component="h2" variant="h4">
            Financial Plan
          </Typography>
          <div className={classes.separator}></div>
          <img src={FinanacialPlanImage} alt='' />
          <Typography>
            Often the most intimidating part of a business plan, the financial plan includes your profit & loss statement, your balance sheet and your cash flow. Our business plan software for salons, spas and studios simplifies this process by asking for your financial numbers like TurboTax does for taxes, and then automating them into clean charts and graphs.
          </Typography>
          <Typography component="h3" variant="h5">
            Profit & Loss Statement
          </Typography>
          <Typography>
            The profit & loss statement breaks down your estimated profit and loss over the first few years of business, taking into account revenues from appointments and client sessions, your inventory costs, operation expenses, employee payroll and corporate taxes.
          </Typography>
          <Typography>
            The expectations for the time it takes to become profitable will vary based on the type of health or beauty company you’re starting. A CrossFit gym, for example, will require a larger upfront investment to acquire all the fitness equipment, locker rooms and necessary space, compared to a yoga studio, which can often only require mats for classes.
          </Typography>
          <Typography>
            In any case, you’ll want your profit & loss to show an upward trajectory over the first few years.
          </Typography>
          <Typography component="h3" variant="h5">
            Balance Sheet
          </Typography>
          <Typography>
            While your profit & loss statement focuses on trends, your balance sheet details your financial status at a set point in time, laying out your assets, liabilities and equity.
          </Typography>
          <Typography component="h3" variant="h5">
            Cash Flow
          </Typography>
          <Typography>
            Lastly, your cash flow shows the net amount of cash or cash-equivalents flowing in and out of your health or beauty business, broken down into operations, investing and financing.
          </Typography>
          <div className={classes.footer}>
            <div className={classes.separator}></div>
            <Typography>
              Need more help writing your spa, studio or salon business plan? Let LaunchPlan walk you through each step.
            </Typography>
            <Typography>
              LaunchPlan lets you collaborate with unlimited team members and then easily download your professional business plan. Impress potential investors and put your company on track for success.
            </Typography>
            <Button
              className={classes.freeTrailButton}
              href={`${process.env.GATSBY_APP_HOST}/signup?selection=FREE_ACCOUNT`}
              onClick={() =>
                trackCustomEvent({
                  category: "Spa Plan Help",
                  action: "Click",
                  label: "Free Trial In Overview",
                })
              }
            >
              {"Start Your LaunchPlan Free Trial"}
            </Button>
          </div>
        </div>
      </section>
    </PublicLayout>
  )
}

export default withStyles(styles)(SpaPage)
